@import "../Register/Register";

.details-page {
  .details-container {
    padding: 20px;
    padding-bottom: 0;

    .form-title {
      margin: 0.3em 0;
      margin-top: 0;
      color: #FFF;
      font-family: RobotoRegular;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.12px;
      text-transform: uppercase;
      padding-top: 3rem;
      margin-top: 0;
      padding-bottom: 1rem;
    }
    .error-payment {
      color: #ffba12;
      text-align: center;
      font-family: Futura;
      font-size: 12px;
    }

    
  }
}
