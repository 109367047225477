@import "../Register/Register";

.congrats-form {
  // box-shadow: -6px 10px 12.7px 0px rgba(0, 0, 0, 0.25);
  // background-color: #ef1c26;
  border-radius: 16px;
  // padding: 1em;
  padding-bottom: 1em;
  width: 95%;
  max-width: 350px;
}
.congratulation-container {
  // padding: 1em;
  color: #fff;
}


.congratulation-title {
  font-family: RobotoBold;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  margin: 0;
}

.congratulation-amt {
  color: #ffef00;
  font-weight: 700;
  margin: 0;
  font-size: 55px;
}
.congratulation-text {
  font-family: GothamBold;
  font-weight: bolder;
  font-size: 1.2em;
  margin-bottom: 0;
  margin-top: 5rem;
  color: #E6BB7B;
}
.congratulation-sub-text {
  // font-family: GothamBold;
  font-weight: bold;
  color: #fff;
}

